import React, { useEffect } from "react";
import { redirectTo } from "src/helpers/route";
const Redirect = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      redirectTo("/curso-javascript/");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return <p>Redirecionando...</p>;
};
export default Redirect;

/*import React from "react";
import CTA from "src/components/CTA";
import About from "src/sections/About";
import Promo from "src/sections/Promo";
import Warranty from "src/sections/Warranty";
import FAQ from "src/sections/FAQ";
import Footer from "src/sections/Footer";
import Pricing from "src/sections/Pricing";
import SEO from "src/components/SEO";
import Course from "src/sections/Course";

import JavaScriptCourse from "../../data/javascript-course.json";
import faqData from "../../data/faq-js.json";
import { NAME, BASE_URL } from "src/helpers/config";

import "../../scss/style.scss";

export default () => {
  const seoProps = {
    title: `Curso de JavaScript com Emerson Brôga - Inscrições Abertas - ${NAME}`,
    description: "Quer aprender JavaScript de uma vez por todas?",
    canonical: `${BASE_URL}/inscricoes-abertas/`,
    image: `${BASE_URL}/meta/og-image-default.jpg`,
  };

  return (
    <>
      <SEO {...seoProps} />
      <Promo />
      <div className="advantages">
        <h2>
          Com esse curso, você vai <span className="highlight">dominar</span>...
        </h2>
        <ol>
          <li>
            <span>JavaScript Assíncrono</span>
            <p>
              Entenda de uma vez por todas o JavaScript assíncrono, domine
              conceitos como callbacks, promises e async/await.
            </p>
          </li>
          <li>
            <span>Organização</span>
            <p>
              Aprenda a organizar seu código JavaScript, criando funções claras
              e eficientes. Chega de <i>código spaghetti</i>.
            </p>
          </li>
          <li>
            <span>Nível internacional</span>
            <p>
              O conteúdo apresentado no curso tem o que eu faço no dia-a-dia
              trabalhando em projetos internacionais de grande porte.
            </p>
          </li>
          <li>
            <span>Paradigmas da linguagem</span>
            <p>
              Domine o paradigma da programação funcional nos módulos avançados.
            </p>
          </li>
          <li>
            <span>Do básico ao avançado</span>
            <p>
              Veja desde conceitos básicos, como variáveis, arrays, objetos até
              conceitos de aprofundados da linguagem como callstack, event loop
              e muito mais.
            </p>
          </li>
        </ol>
        <img
          loading="lazy"
          className="illustration"
          src="/static/illustrations/placeholders_rklh.svg"
          alt="O que você vai dominar com esse curso"
        />
      </div>

      <Pricing />

      <Course courses={[JavaScriptCourse]} />

      <div className="bonus">
        <div className="inner-container">
          <h2>
            <span className="highlight">Bônus</span>
          </h2>
          <p>
            Você vai levar também, totalmente grátis o meu livro&nbsp;
            <span className="highlight">
              JavaScript&#58; As novidades do ES6
            </span>
            &nbsp; no formato digital.
          </p>
          <div className="e-book">
            <img
              loading="lazy"
              src="https://emersonbroga.com/wp-content/uploads/2020/12/450x450-template-001.png"
              alt="JavaScript&colon; As novidades do ES6"
            />
          </div>
          <div className="pricing">
            <div className="full">
              <sup>De</sup>
              <span>
                47<sup>.00</sup>
              </span>
            </div>
          </div>
          <p>
            Após anos e anos trabalhando com JavaScript, eu decidi dedicar meu
            tempo para escrever um livro digital contando as Novidades do ES6.
          </p>
          <p>
            Trazendo um guia prático e de fácil leitura com exemplos claros,
            este e-book veio para esclarecer suas dúvidas quanto a
            arrow-functions, rest operator, spread operator e muito mais. Deixe
            seu código com uma escrita mais limpa e clara, aproveite as novas
            funcionalidades e eleve o seu nível de JavaScript.
          </p>
          <p>
            Se especialize no que o mercado de trabalho está exigindo, seja um
            programador atualizado e mais valorizado.
          </p>
          <p>
            Com o formato digital, você pode ler no kindle, celular, tablet em
            qualquer hora e lugar que desejar.
          </p>

          <img
            loading="lazy"
            className="illustration right"
            src="/static/illustrations/absorbed_in_xahs.svg"
            alt="Livro no formato digital para ler onde você quiser"
          />
        </div>
      </div>

      <Warranty />
      <CTA />
      <FAQ data={faqData.data} />

      <About />
      <Footer />
    </>
  );
  /*
  return (
    <div className="body-wrap boxed-container">
      <header className="site-header">
        <div className="container">
          <div className="site-header-inner">
            <div className="brand header-brand">
              <h1 className="m-0">
                <a href="#">
                  <img
                    loading="lazy"
                    className="header-logo-image asset-light"
                    src="https://emersonbroga.com/e/assets/emersonbroga-logo-b-pink.png"
                    alt="Logo"
                  />
                  <img
                    loading="lazy"
                    className="header-logo-image asset-dark"
                    src="https://emersonbroga.com/e/assets/emersonbroga-logo-b-pink.png"
                    alt="Logo"
                  />
                </a>
              </h1>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="hero">
          <div className="container">
            <div className="hero-inner">
              <div className="hero-copy">
                <h1 className="hero-title mt-0">
                  Dê o primeiro passo para uma carreira de nível internacional
                </h1>
                <p className="hero-paragraph">
                  Trabalhe de qualquer lugar do mundo, para qualquer lugar do
                  mundo e ganhe os ótimos salários que a profissão de
                  programador pode proporcionar.
                </p>
                <p className="hero-paragraph">
                  Alcance os seus objetivos aprendendo JavaScript de verdade.
                </p>
                <div className="hero-cta">
                  <a className="button button-primary" href="#">
                    Buy it now
                  </a>
                  <div className="lights-toggle">
                    <input
                      id="lights-toggle"
                      type="checkbox"
                      name="lights-toggle"
                      className="switch"
                      checked="checked"
                    />
                    <label for="lights-toggle" className="text-xs">
                      <span>
                        Turn me <span className="label-text">dark</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="hero-media">
                <div className="header-illustration">
                  <img
                    loading="lazy"
                    className="header-illustration-image asset-light"
                    src="/static/images/header-illustration-light.svg"
                    alt="Header illustration"
                  />
                  <img
                    loading="lazy"
                    className="header-illustration-image asset-dark"
                    src="/static/images/header-illustration-dark.svg"
                    alt="Header illustration"
                  />
                </div>
                <div className="hero-media-illustration">
                  <img
                    loading="lazy"
                    className="hero-media-illustration-image asset-light"
                    src="/static/images/hero-media-illustration-light.svg"
                    alt="Hero media illustration"
                  />
                  <img
                    loading="lazy"
                    className="hero-media-illustration-image asset-dark"
                    src="/static/images/hero-media-illustration-dark.svg"
                    alt="Hero media illustration"
                  />
                </div>
                <div className="hero-media-container">
                  <img
                    loading="lazy"
                    className="hero-media-image asset-light"
                    src="/static/images/538x380-broga-la.png"
                    alt="Hero media"
                  />
                  <img
                    loading="lazy"
                    className="hero-media-image asset-dark"
                    src="/static/images/538x380-broga-la.png"
                    alt="Hero media"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features section">
          <div className="container">
            <div className="features-inner section-inner has-bottom-divider">
              <div className="features-header text-center">
                <div className="container-sm">
                  <h2 className="section-title mt-0">
                    JavaScript com Emerson Brôga
                  </h2>
                  <p className="section-paragraph">
                    Aprenda JavaScript do zero ao avançado, com quem tem
                    experiência internacional e didática excelente.
                  </p>
                  <div className="features-image">
                    <img
                      loading="lazy"
                      className="features-illustration asset-dark"
                      src="/static/images/features-illustration-dark.svg"
                      alt="Feature illustration"
                    />
                    <img
                      loading="lazy"
                      className="features-box asset-dark"
                      src="/static/images/features-box-dark.svg"
                      alt="Feature box"
                    />
                    <img
                      loading="lazy"
                      className="features-illustration asset-dark"
                      src="/static/images/features-illustration-top-dark.svg"
                      alt="Feature illustration top"
                    />
                    <img
                      loading="lazy"
                      className="features-illustration asset-light"
                      src="/static/images/features-illustration-light.svg"
                      alt="Feature illustration"
                    />
                    <img
                      loading="lazy"
                      className="features-box asset-light"
                      src="/static/images/features-box-light.svg"
                      alt="Feature box"
                    />
                    <img
                      loading="lazy"
                      className="features-illustration asset-light"
                      src="/static/images/features-illustration-top-light.svg"
                      alt="Feature illustration top"
                    />
                  </div>
                </div>
              </div>
              <div className="features-wrap">
                <div className="feature is-revealing">
                  <div className="feature-inner">
                    <div className="feature-icon">
                      <img
                        loading="lazy"
                        className="asset-light"
                        src="/static/images/feature-01-light.svg"
                        alt="Feature 01"
                      />
                      <img
                        loading="lazy"
                        className="asset-dark"
                        src="/static/images/feature-01-dark.svg"
                        alt="Feature 01"
                      />
                    </div>
                    <div className="feature-content">
                      <h3 className="feature-title mt-0">Discover</h3>
                      <p className="text-sm mb-0">
                        Lorem ipsum dolor sit amet, consecte adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua dui.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feature is-revealing">
                  <div className="feature-inner">
                    <div className="feature-icon">
                      <img
                        loading="lazy"
                        className="asset-light"
                        src="/static/images/feature-02-light.svg"
                        alt="Feature 02"
                      />
                      <img
                        loading="lazy"
                        className="asset-dark"
                        src="/static/images/feature-02-dark.svg"
                        alt="Feature 02"
                      />
                    </div>
                    <div className="feature-content">
                      <h3 className="feature-title mt-0">Discover</h3>
                      <p className="text-sm mb-0">
                        Lorem ipsum dolor sit amet, consecte adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua dui.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feature is-revealing">
                  <div className="feature-inner">
                    <div className="feature-icon">
                      <img
                        loading="lazy"
                        className="asset-light"
                        src="/static/images/feature-03-light.svg"
                        alt="Feature 03"
                      />
                      <img
                        loading="lazy"
                        className="asset-dark"
                        src="/static/images/feature-03-dark.svg"
                        alt="Feature 03"
                      />
                    </div>
                    <div className="feature-content">
                      <h3 className="feature-title mt-0">Discover</h3>
                      <p className="text-sm mb-0">
                        Lorem ipsum dolor sit amet, consecte adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua dui.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta section">
          <div className="container-sm">
            <div className="cta-inner section-inner">
              <div className="cta-header text-center">
                <h2 className="section-title mt-0">Get it and Switch</h2>
                <p className="section-paragraph">
                  Lorem ipsum is common placeholder text used to demonstrate the
                  graphic elements of a document or visual presentation.
                </p>
                <div className="cta-cta">
                  <a className="button button-primary" href="#">
                    Buy it now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="site-footer has-top-divider">
        <div className="container">
          <div className="site-footer-inner">
            <div className="brand footer-brand">
              <a href="#">
                <img
                  loading="lazy"
                  className="asset-light"
                  src="/static/images/logo-light.svg"
                  alt="Logo"
                />
                <img
                  loading="lazy"
                  className="asset-dark"
                  src="/static/images/logo-dark.svg"
                  alt="Logo"
                />
              </a>
            </div>
            <ul className="footer-links list-reset">
              <li>
                <a href="#">Contact</a>
              </li>
              <li>
                <a href="#">About us</a>
              </li>
              <li>
                <a href="#">FAQ's</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
            </ul>
            <ul className="footer-social-links list-reset">
              <li>
                <a href="#">
                  <span className="screen-reader-text">Facebook</span>
                  <svg
                    width="16"
                    height="16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="screen-reader-text">Twitter</span>
                  <svg
                    width="16"
                    height="16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="screen-reader-text">Google</span>
                  <svg
                    width="16"
                    height="16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <div className="footer-copyright">
              &copy; 2018 Switch, all rights reserved
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
};
*/
